import {
    AddressComponent,
    DangerousGoodsIndicator,
    HandlingUnitCountByFreightType,
    MoreAddressesLabel,
    TimeWindowComponent,
    TransportOrderActionsDropdown,
    TransportOrderStatusOrAcceptBadge,
    TransportTypes,
    VolumesByFreightType,
    WeightsByFreightType,
} from "../orderOverviewSubcomponents"
import { sortShipmentByDueDate } from "../../../helper/shipmentHelper"
import {
    Shipment,
    ShipmentStatus,
    TransportOrder,
    TransportOrderType
} from "../../../model/transportorder/transportOrder"
import {
    summarizeMeasurements,
    TransportOrderMeasurementSummary
} from "../../../model/transportorder/transportOrderMeasurements"
import { formatDateColumn } from "../../../helper/formatHelper"
import { ReactNode } from "react"
import { isAnyShipmentConfirmed } from "../../../model/transportorder/transportOrderEditing"
import Tooltip from "@rio-cloud/rio-uikit/Tooltip"
import { FormattedMessage } from "react-intl"
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger"

const notCancelled = (shipment: Shipment): boolean => shipment.status !== ShipmentStatus.CANCELLED
const findFirst = (shipments: Shipment[]): Shipment | undefined => {
    return shipments.find(notCancelled)
}
const findLast = (shipments: Shipment[]): Shipment | undefined => {
    return shipments.reverse().find(notCancelled)
}

export const getEarliestLoadingTimeWindow = (transportOrder: TransportOrder) => {
    const timeFroms = transportOrder._embedded?.shipments
        ?.filter(shipment => shipment.status !== ShipmentStatus.CANCELLED)
        .map((it) => it.loading_address?.time_window?.time_from)
        .filter(it => it !== undefined) || []
    return timeFroms.length === 0
        ? undefined
        : timeFroms.reduce((a, b) => {
            return new Date(a as string).getTime() < new Date(b as string).getTime() ? a : b
        })
}

export const TransportOrderTableRow = (
    props: {
        transportOrder: TransportOrder,
        onDetailsClicked: (_: TransportOrder) => void,
        onAcceptClicked: (_: TransportOrder) => Promise<void>,
    }
): ReactNode => {

    const { transportOrder, onDetailsClicked, onAcceptClicked } = props

    const shipments = transportOrder._embedded?.shipments || []
    const sortedShipments = sortShipmentByDueDate(shipments)

    const firstShipment = findFirst(sortedShipments)
    const lastShipment = findLast(sortedShipments)

    const firstLoadingAddress = firstShipment ? firstShipment.loading_address : undefined
    const lastUnloadingAddress = lastShipment ? lastShipment.unloading_address : undefined
    const accepted = isAnyShipmentConfirmed(transportOrder)
    const atLeastOneTimeSlotIdIsMissing = transportOrder._embedded?.shipments.some(
        shipment => shipment.confirmationDraft.accessCodeAtUnloading === undefined
    )
    const atLeastOneAssetAssignmentIsMissing = transportOrder._embedded?.shipments.some(
        shipment => {
            const asset = shipment.confirmationDraft.asset
            return asset === undefined || (asset.id === undefined && asset.licensePlate === undefined)
        }
    )
    const isMaterialTransport = transportOrder.orderType === TransportOrderType.MATERIAL
    const hasWarning = isMaterialTransport && accepted && (atLeastOneTimeSlotIdIsMissing || atLeastOneAssetAssignmentIsMissing)

    const measurementSummary: TransportOrderMeasurementSummary = summarizeMeasurements(transportOrder)

    return (
        <tr data-testid={`transportOrderId_${transportOrder.id}`} className={`${transportOrder.id} ${hasWarning ? "warning" : ""}`}>
            <td>
                <div className="space-y-10">
                    { hasWarning && atLeastOneTimeSlotIdIsMissing &&
                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip id="tooltip" allowOnTouch width={300} textAlignment={"left"}>
                                    <FormattedMessage id="transportOrder.tooltip.timeslotBookingIdMissing"/>
                                </Tooltip>
                            }
                        >
                            <span data-testid={"TimeBookingWarningIcon"} className="rioglyph rioglyph-time-alt text-color-warning text-size-h5"/>
                        </OverlayTrigger>
                    }
                    { hasWarning && atLeastOneAssetAssignmentIsMissing &&
                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip id="tooltip" allowOnTouch width={300} textAlignment={"left"}>
                                    <FormattedMessage id="transportOrder.tooltip.assetAssignmentMissing"/>
                                </Tooltip>
                            }
                        >
                            <span data-testid={"AssetAssignmentWarningIcon"} className="rioglyph rioglyph-truck text-color-warning text-size-h5"/>
                        </OverlayTrigger>
                    }
                </div>
            </td>
            <td>
                {formatDateColumn(transportOrder.created_at)}
            </td>
            <td>
                {formatDateColumn(getEarliestLoadingTimeWindow(transportOrder))}
            </td>
            <td>
                <div className="display-flex flex-row">
                    <span className="text-normal ellipsis-1">{transportOrder.customer_data?.name ?? ""}</span>
                </div>
            </td>
            <td>
                <TimeWindowComponent firstLoadingAddress={firstLoadingAddress}
                    lastUnloadingAddress={lastUnloadingAddress}/>
            </td>
            <td>
                <div className="display-flex flex-row no-wrap align-items-center">
                    <AddressComponent loadingAddress={firstLoadingAddress}/>
                    <MoreAddressesLabel shipments={sortedShipments.filter(notCancelled)} loadingAddressType={"LOAD"}/>
                </div>
            </td>
            <td>
                <div className="display-flex flex-row no-wrap align-items-center">
                    <AddressComponent loadingAddress={lastUnloadingAddress}/>
                    <MoreAddressesLabel shipments={sortedShipments.filter(notCancelled)} loadingAddressType={"UNLOAD"}/>
                </div>
            </td>
            <td>
                <div className="display-flex flex-column">
                    <TransportTypes shipments={shipments}/>
                </div>
            </td>
            <td>
                <div className="display-flex flex-column">
                    <WeightsByFreightType summary={measurementSummary}/>
                </div>
            </td>
            <td>
                <div className="display-flex flex-column">
                    <VolumesByFreightType summary={measurementSummary}/>
                </div>
            </td>
            <td>
                <div className="display-flex flex-column">
                    <HandlingUnitCountByFreightType summary={measurementSummary}/>
                </div>
            </td>
            <td>
                <div className="display-flex flex-column">
                    <DangerousGoodsIndicator transportOrder={transportOrder}/>
                </div>
            </td>
            <td>
                <div className="display-flex flex-column">
                    <div>
                        <TransportOrderStatusOrAcceptBadge
                            transportOrder={transportOrder}
                            onAcceptClicked={onAcceptClicked}
                        />
                    </div>
                </div>
            </td>
            <td className="table-action">
                <span>
                    <TransportOrderActionsDropdown
                        transportOrder={transportOrder}
                        onDetailsClicked={onDetailsClicked}
                        onAcceptClicked={onAcceptClicked}
                    />
                </span>
            </td>
        </tr>
    )
}
