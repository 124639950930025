import { ReactElement, useEffect, useRef, useState } from "react"
import { ShipmentStatus, TransportOrder } from "../../model/transportorder/transportOrder"
import { FormattedMessage } from "react-intl"
import { routingConfig } from "../Application/RoutingConfig"
import { TransportOrderPresenter } from "./TransportOrderPresenter"
import { canTransportOrderBeAccepted } from "../../model/transportorder/transportOrderEditing"
import { IStickyFooterProps, StickyFooter } from "./TransportOrderFrameFooter"
import {
    areShipmentConfirmationsEqual,
    confirmationsForNonCancelled,
    ShipmentConfirmation
} from "../../model/confirmation/Confirmation"
import { AnnouncementApplicationBanner } from "../Application/AnnouncementApplicationBanner"
import { fetchTransportOrderBundle } from "../../actions/fetchTransportOrderBundles"
import { confirmShipments } from "../../actions/freightForwarderResponses"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import queryString from "query-string"
import { ValidationContextProvider, ValidationContextState } from "../Form/validation/VaildationContext"
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout"
import ApplicationLayoutBodyBottomBar from "@rio-cloud/rio-uikit/ApplicationLayoutBodyBottomBar"
import Spinner from "@rio-cloud/rio-uikit/Spinner"
import NotFoundState from "@rio-cloud/rio-uikit/NotFoundState"
import Notification from "@rio-cloud/rio-uikit/Notification"
import ErrorState from "@rio-cloud/rio-uikit/ErrorState"
import { getFailedFetchTransportOrderId, getTransportOrder } from "../../redux/transportorder.selector"
import { useAppDispatch, useAppSelector } from "../../redux/store"

enum RenderContentType {
    LOADING_SPINNER,
    NOT_FOUND_ERROR,
    TRANSPORT_ORDER
}

export type TransportOrderId = string

const buildReturnUrl = (search: string): string | undefined => {
    if (!search) {
        return undefined
    }

    const query = queryString.parse(search.substring(1))
    const redirectUri = query["redirect_uri"]
    if (typeof redirectUri === "string") {
        return redirectUri
    } else if (Array.isArray(redirectUri) && redirectUri[0]) {
        return redirectUri[0] || undefined
    } else {
        return undefined
    }
}

export const TransportOrderFrame = (): ReactElement => {
    const transportOrderId = useParams<{ id: string | undefined }>().id ?? ""
    const transportOrder = useAppSelector(state => getTransportOrder(state, transportOrderId))
    const [previousRenderedTransportOrder, setPreviousRenderedTransportOrder] = useState<TransportOrder | undefined>(undefined)

    const navigate = useNavigate()
    const { search } = useLocation()

    const returnUrl = buildReturnUrl(search)
    const confirmationValidationContextState = useRef(new ValidationContextState())

    const reloadFailed = useAppSelector(getFailedFetchTransportOrderId) === transportOrderId
    const [editableConfirmations, setEditableConfirmations] = useState<ShipmentConfirmation[]>(getEditableConfirmations(transportOrder))
    const [transportOrderConfirmationsInProgress, setTransportOrderConfirmationsInProgress] = useState<TransportOrderId[]>([])

    const dispatch = useAppDispatch()

    // Required if the TO isn't in the store yet (e.g. when using Deep Links)
    useEffect(() => {
        if (!transportOrder) {
            dispatch(fetchTransportOrderBundle(transportOrderId))
        }
    }, [transportOrder, dispatch, transportOrderId])

    // If the TO has been loaded after the initial render, we need to update the editable confirmations
    // But only if the TO has been loaded the first time (not when the automatic polling kicked in)
    useEffect(() => {
        if (transportOrder?.id !== previousRenderedTransportOrder?.id) {
            setEditableConfirmations(getEditableConfirmations(transportOrder))
        }
        setPreviousRenderedTransportOrder(transportOrder)
    }, [transportOrder, previousRenderedTransportOrder?.id])

    const getStickyFooterProps = (): IStickyFooterProps => {
        return {
            acceptButtonProps: {
                show: transportOrder ? canTransportOrderBeAccepted(transportOrder) : false,
                onClick: handleAcceptButtonClicked,
                validationContext: confirmationValidationContextState.current,
                transportOrder,
                confirmations: editableConfirmations.map(it => it.confirmation),
                enabled: hasUnconfirmedOrEditedConfirmations() && !(transportOrderConfirmationsInProgress.findIndex(it => it == transportOrderId) >= 0)
            },
            backButtonProps: {
                show: true,
                onClick: navigateToPreviousPage,
            },
            printButtonProps: {
                show: transportOrder != undefined,
                onClick: window.print
            }
        }
    }

    const determineRenderContentType = (): RenderContentType => {
        if (transportOrder) {
            return RenderContentType.TRANSPORT_ORDER
        } else {
            if (!reloadFailed) {
                return RenderContentType.LOADING_SPINNER
            } else {
                return RenderContentType.NOT_FOUND_ERROR
            }
        }
    }

    const renderContent = (contentType: RenderContentType): ReactElement => {
        switch (contentType) {
        case RenderContentType.TRANSPORT_ORDER:
            return (
                <TransportOrderPresenter
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    transportOrder={transportOrder!}
                    onConfirmationsChange={setEditableConfirmations}
                />
            )
        case RenderContentType.LOADING_SPINNER:
            // TODO: internationalize
            return <LoadingSpinner text={"Loading Transport Order..."}/>
        case RenderContentType.NOT_FOUND_ERROR:
            // TODO: internationalize
            return (<NotFoundState
                headline="Nothing found"
                message="The Transport Order you are looking for could not be found or you don't have access to it."
            />)
        default:
            return (<ErrorState
                headline={<FormattedMessage id={"application.error.state.headline"}/>}
                message={<FormattedMessage id={"application.error.state.message"}/>}
            />)
        }
    }

    const hasUnconfirmedOrEditedConfirmations = (): boolean => {
        const originalConfirmations = confirmationsForNonCancelled(getEditableConfirmations(transportOrder))
        const editedConfirmations = confirmationsForNonCancelled(editableConfirmations)
        const hasUnconfirmedShipments = transportOrder?._embedded?.shipments.some(
            it => it.status !== ShipmentStatus.CANCELLED && !it.isConfirmed)
        const userHasEditedConfirmations = !areShipmentConfirmationsEqual(originalConfirmations, editedConfirmations)

        return hasUnconfirmedShipments || userHasEditedConfirmations
    }

    const handleAcceptButtonClicked = (): Promise<void> => {
        const newTransportOrderConfirmationsInProgress = [...transportOrderConfirmationsInProgress, transportOrderId]
        setTransportOrderConfirmationsInProgress(newTransportOrderConfirmationsInProgress)

        return dispatch(confirmShipments(confirmationsForNonCancelled(editableConfirmations)))
            .then(() => dispatch(fetchTransportOrderBundle(transportOrderId)))
            .then(() => {
                Notification.success(<div data-testid={"notification-confirmation-success"}>
                    <FormattedMessage id={"transportOrder.confirmation.success"}/>
                </div>)
                return Promise.resolve()
            })
            .catch(err => {
                Notification.error(<FormattedMessage id={"transportOrder.error.confirm"}/>)
                return Promise.reject(err)
            })
            .finally(() => {
                setTransportOrderConfirmationsInProgress(transportOrderConfirmationsInProgress.filter(it => it != transportOrderId))
            })
    }

    const navigateToPreviousPage = (): void => {
        if (returnUrl) {
            if (returnUrl.startsWith(routingConfig.transportOrderOverview.path)) {
                navigate(returnUrl, { state: {
                    scrollPositionToId: transportOrderId
                } })
            } else {
                window.location.href = returnUrl
            }
        } else {
            navigate(routingConfig.transportOrderOverview.path, { state: {
                scrollPositionToId: transportOrderId
            } })
        }
    }

    const contentType = determineRenderContentType()

    return (
        <ValidationContextProvider value={confirmationValidationContextState.current}>
            <ApplicationLayout.Body className={"print-area"}
                banner={<AnnouncementApplicationBanner/>}
                bottomBar={
                    <ApplicationLayoutBodyBottomBar className={"non-printable"}>
                        <StickyFooter {...getStickyFooterProps()} />
                    </ApplicationLayoutBodyBottomBar>
                }
            >
                {
                    renderContent(contentType)
                }
            </ApplicationLayout.Body>
        </ValidationContextProvider>
    )
}

const getEditableConfirmations = (transportOrder: TransportOrder | undefined): ShipmentConfirmation[] => {
    return (transportOrder?._embedded?.shipments ?? [])
        .map(shipment => ({
            shipmentId: shipment.id,
            shipmentStatus: shipment.status,
            confirmation: shipment.confirmationDraft,
            version: shipment.version,
        }))
}

export const LoadingSpinner = (props: { text: string }): ReactElement => {
    return (
        <div className={`spinnerInfoBox col-xs-12`}>
            <div className="spinnerIcon">
                <Spinner isDoubleSized={true}/>
            </div>
            <div className="spinnerText">{props.text}</div>
        </div>
    )
}
