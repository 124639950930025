import { FormattedMessage, useIntl } from "react-intl"
import { ReactNode, useEffect, useState } from "react"
import IframeResizer from "iframe-resizer-react"

import Button from "@rio-cloud/rio-uikit/Button"
import Card from "@rio-cloud/rio-uikit/Card"
import Divider from "@rio-cloud/rio-uikit/Divider"
import Notification from "@rio-cloud/rio-uikit/Notification"
import RadioButton from "@rio-cloud/rio-uikit/RadioButton"
import useClipboard from "@rio-cloud/rio-uikit/useClipboard"

import { config } from "../../configuration/app/app-config"

enum PAGE_TAB {UNSELECTED, ONBOARDING, TECHNICAL}
enum TECH_TAB_CONTENT {SHOW_NONE, SHOW_FORM, SHOW_VW, SHOW_MAN}

interface ContactFormProps {
    showOnboarding: boolean
}

export function ContactForm(props: ContactFormProps): ReactNode {
    const intl = useIntl()

    const [formTabState, setFormTabState] = useState<PAGE_TAB>(props.showOnboarding ? PAGE_TAB.ONBOARDING : PAGE_TAB.UNSELECTED)
    const [technicalTabState, setTechnicalTabState] = useState<TECH_TAB_CONTENT>(TECH_TAB_CONTENT.SHOW_NONE)
    const [selectedOption, setSelectedOption] = useState<TECH_TAB_CONTENT>(TECH_TAB_CONTENT.SHOW_NONE)

    const emailMan = "shippersupport-man@rio.cloud"
    const emailVw = "shippersupport-vw@rio.cloud"
    const nameMan = "MAN"
    const nameVw = "Volkswagen"
    const textCopied = intl.formatMessage({ id: "supportDialog.text.copied" })
    const clipboard = useClipboard()

    useEffect(() => {
        clipboard.copied && Notification.default(textCopied)
    }, [clipboard.copied, textCopied])

    const getSelectedClass = (isSelected: boolean) =>
        isSelected ? "border-color-highlight bg-highlight-decent z-index-1" : ""

    const showSelect =
        <Card className="display-flex justify-content-center align-items-center min-height-200 shadow-subtle">
            <div className="text-center text-size-h3 text-color-gray">
                <span className="rioglyph rioglyph-support text-size-400pct"></span><br/>
                <FormattedMessage id={"supportDialog.please.select"}/>
            </div>
        </Card>

    return (
        <div className="margin-left-50 margin-right-50 margin-top-10">
            <div className="display-flex justify-content-center align-items-center margin-bottom-10">
                <h3 className="headline_text"><FormattedMessage id={"supportDialog.contact.headline"}/></h3>
            </div>
            <div className="display-flex justify-content-center align-items-center margin-bottom-50">
                <FormattedMessage id={"supportDialog.contact.subheadline"}/>
            </div>
            <div className="display-flex gap-20 margin-top-10 padding-bottom-10">
                <Button bsStyle={formTabState === PAGE_TAB.ONBOARDING ? Button.PRIMARY : Button.MUTED_FILLED}
                    bsSize={Button.LG}
                    onClick={() => setFormTabState(PAGE_TAB.ONBOARDING)}
                    className="flex-basis-100pct text-size-18">
                    <FormattedMessage id={"supportDialog.button.onboarding"}/>
                </Button>
                <Button bsStyle={formTabState === PAGE_TAB.TECHNICAL ? Button.PRIMARY : Button.MUTED_FILLED}
                    bsSize={Button.LG}
                    onClick={() => setFormTabState(PAGE_TAB.TECHNICAL)}
                    className="flex-basis-100pct text-size-18 line-height-20">
                    <FormattedMessage id={"supportDialog.button.technical"}/>
                </Button>
            </div>
            <Divider className="margin-bottom-10"/>

            {(formTabState === PAGE_TAB.TECHNICAL) ? (
                <div>
                    <div className="display-flex flex-column margin-bottom-20">
                        <RadioButton
                            name='contactFormSelectRadios'
                            custom
                            className={`padding-15 rounded-top-left rounded-top-right border ${getSelectedClass(
                                selectedOption === TECH_TAB_CONTENT.SHOW_VW
                            )}`}
                            onChange={() => setSelectedOption(TECH_TAB_CONTENT.SHOW_VW)}
                            checked={selectedOption === TECH_TAB_CONTENT.SHOW_VW}
                            onClick={() => setTechnicalTabState(TECH_TAB_CONTENT.SHOW_VW)}>
                            <div className='radio-text-wrapper display-flex'>
                                <span className='radio-text'/>
                                <div className='margin-left-5 margin-right-5'>
                                    <div className='text-medium'><FormattedMessage
                                        id={"supportDialog.technical.common.radio1"} values={{
                                            _underline: <u>{intl.formatMessage({ id: "supportDialog.technical.common.radio1.underline" }, { _name: nameVw })} </u>
                                        }}/></div>
                                    <div className='text-color-dark'><FormattedMessage
                                        id={"supportDialog.technical.common.radio2"} values={{
                                            _name: <u>{nameVw}</u>
                                        }}/></div>
                                </div>
                            </div>
                        </RadioButton>
                        <RadioButton
                            name='contactFormSelectRadios'
                            custom
                            className={`padding-15 margin-top--1 border ${getSelectedClass(
                                selectedOption === TECH_TAB_CONTENT.SHOW_MAN
                            )}`}
                            onChange={() => setSelectedOption(TECH_TAB_CONTENT.SHOW_MAN)}
                            checked={selectedOption === TECH_TAB_CONTENT.SHOW_MAN}
                            onClick={() => setTechnicalTabState(TECH_TAB_CONTENT.SHOW_MAN)}>
                            <div className='radio-text-wrapper display-flex'>
                                <span className='radio-text'/>
                                <div className='margin-left-5 margin-right-5'>
                                    <div className='text-medium'><FormattedMessage
                                        id={"supportDialog.technical.common.radio1"} values={{
                                            _underline: <u>{intl.formatMessage({ id: "supportDialog.technical.common.radio1.underline" }, { _name: nameMan })}</u>,
                                        }}/></div>
                                    <div className='text-color-dark'><FormattedMessage
                                        id={"supportDialog.technical.common.radio2"} values={{
                                            _name: <u>{nameMan}</u>
                                        }}/></div>
                                </div>
                            </div>
                        </RadioButton>
                        <RadioButton
                            name='contactFormSelectRadios'
                            custom
                            className={`padding-15 margin-top--1 rounded-bottom-left rounded-bottom-right border ${getSelectedClass(
                                selectedOption === TECH_TAB_CONTENT.SHOW_FORM
                            )}`}
                            onChange={() => setSelectedOption(TECH_TAB_CONTENT.SHOW_FORM)}
                            checked={selectedOption === TECH_TAB_CONTENT.SHOW_FORM}
                            onClick={() => setTechnicalTabState(TECH_TAB_CONTENT.SHOW_FORM)}>
                            <div className='radio-text-wrapper display-flex'>
                                <span className='radio-text'/>
                                <div className='margin-left-5 margin-right-5'>
                                    <div className='text-medium'><FormattedMessage
                                        id={"supportDialog.technical.tech.radio1"} values={{
                                            _underline: <u>{intl.formatMessage({ id: "supportDialog.technical.tech.radio1.underline" })}</u>
                                        }}/></div>
                                    <div className='text-color-dark'><FormattedMessage
                                        id={"supportDialog.technical.tech.radio2"}/></div>
                                </div>
                            </div>
                        </RadioButton>
                    </div>

                    {(technicalTabState === TECH_TAB_CONTENT.SHOW_VW) ? (
                        <Card className="display-flex justify-content-center align-items-center min-height-200 shadow-subtle">
                            <div>
                                <div className="text-color-dark text-bold text-size-h5 text-center">
                                    <FormattedMessage
                                        id={"supportDialog.technical.common.text"}/></div>
                                <div className="text-color-primary text-bold text-size-h1 text-center">
                                    {emailVw}
                                    <Button
                                        bsStyle='muted'
                                        className='margin-left-10'
                                        onClick={() => clipboard.copy(emailVw)}
                                        iconName='rioglyph-duplicate'
                                        iconOnly
                                    />
                                </div>
                            </div>
                        </Card>
                    ) : ""}
                    {(technicalTabState === TECH_TAB_CONTENT.SHOW_MAN) ? (
                        <Card className="display-flex justify-content-center align-items-center min-height-200 shadow-subtle">
                            <div>
                                <div className="text-color-dark text-bold text-size-h5 text-center">
                                    <FormattedMessage
                                        id={"supportDialog.technical.common.text"}/></div>
                                <div className="text-color-primary text-bold text-size-h1 text-center">
                                    {emailMan}
                                    <Button
                                        bsStyle='muted'
                                        className='margin-left-10'
                                        onClick={() => clipboard.copy(emailMan)}
                                        iconName='rioglyph-duplicate'
                                        iconOnly
                                    />
                                </div>
                            </div>
                        </Card>
                    ) : ""}
                    {(technicalTabState === TECH_TAB_CONTENT.SHOW_FORM) ? (
                        <Card className="shadow-subtle">
                            <div className="min-height-200">
                                <IframeResizer
                                    className="iFrameResizer"
                                    src={config.contactForm.url}
                                />
                            </div>
                        </Card>
                    ) : ""}
                    {(technicalTabState === TECH_TAB_CONTENT.SHOW_NONE) ? (
                        showSelect
                    ) : ""}
                </div>
            ) : ""}
            {(formTabState === PAGE_TAB.ONBOARDING) ? (
                <Card className="shadow-subtle">
                    <div className="text-center text-medium margin-bottom-20 text-size-h4">
                        <FormattedMessage id={"supportDialog.onboarding.hint"}/>
                    </div>
                    <div className="min-height-200">
                        <IframeResizer
                            className="iFrameResizer"
                            src={config.contactForm.url}
                        />
                    </div>
                </Card>
            ) : ""}
            {(formTabState === PAGE_TAB.UNSELECTED) ? (
                showSelect
            ) : ""}
        </div>
    )
}
