import { Navigate, NavLink, Route, Routes } from "react-router-dom"
import * as React from "react"
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout"
import NotificationsContainer from "@rio-cloud/rio-uikit/NotificationsContainer"
import ApplicationHeader from "@rio-cloud/rio-uikit/ApplicationHeader"
import ActionBarItem from "@rio-cloud/rio-uikit/ActionBarItem"
import { routingConfig } from "./RoutingConfig"
import { FormattedMessage, injectIntl, IntlProvider, useIntl, WrappedComponentProps } from "react-intl"
import { DefaultUserMenu } from "@rio-cloud/rio-user-menu-component"
import IframeResizer from "iframe-resizer-react"
import { Agreement } from "../Agreement/Agreement"
import { WelcomePage } from "../WelcomePage/WelcomePage"
import { TransportOrderFrame } from "../TransportOrder/TransportOrderFrame"
import { OrderOverview } from "../TransportOrder/OrderOverview"
import { ClosingDaysFrame } from "../ClosingDays/ClosingDaysFrame"
import { NotificationSettings } from "../Notifications/NotificationSettings"
import { useFeatureToggle } from "../../configuration/featuretoggle/hooks"
import { getLanguageSettings } from "../../redux/lang.selector"
import { useAppSelector } from "../../redux/store"
import { config } from "../../configuration/app/app-config"
import { useState } from "react"
import { OnboardingTip } from "@rio-cloud/rio-uikit"
import Dialog from "@rio-cloud/rio-uikit/Dialog"
import { Faq } from "../WelcomePage/Faq"
import Button from "@rio-cloud/rio-uikit/Button"
import { ContactForm } from "../WelcomePage/ContactForm"
import { CookiesProvider } from "react-cookie"

const menu = <IframeResizer className="iFrameResizer" src={"https://menu.rio.cloud"}/>

const ClosingDaysNavLinkComponent = (): React.ReactElement => {
    const [showOnboardingTip, setShowOnboardingTip] = useState(true)
    const intl = useIntl()

    const showClosingDaysOnboardingTipUntil = "2024-12-01"
    const localStorageDontShowAgainProperty = `onboardingTip_closingDays_dontShowAgain`

    const badgeNewFeature = (
        <span className={"badge badge-secondary margin-left-5 text-uppercase"}>
            <FormattedMessage id={"navbar.badge.new"}/>
        </span>
    )
    const adminNavLink = <NavLink
        data-track-ga-event-trigger="click"
        data-track-ga-event-category="dispatch, navigationMenu, closingDays"
        data-track-ga-event-action="Link"
        to={`${routingConfig.closingDays.path}`}
    >
        <FormattedMessage id={"navbar.navigationitem.closingdays"}/>
        {new Date() < new Date(showClosingDaysOnboardingTipUntil) ? badgeNewFeature : null}
    </NavLink>

    const shouldShowOnboardingTip = new Date() < new Date(showClosingDaysOnboardingTipUntil)
    const dontShowAgain = localStorage.getItem(localStorageDontShowAgainProperty) === "true"
    if (!shouldShowOnboardingTip || dontShowAgain) {
        return adminNavLink
    }

    const handleDontShowAgain = () => {
        localStorage.setItem(localStorageDontShowAgainProperty, "true")
    }

    return (
        <OnboardingTip
            show={showOnboardingTip}
            placement="bottom"
            width={500}
            clickflow
            textAlignment="left"
            title={<FormattedMessage id={"navbar.navigationitem.closingdays.onboardingtip.title"}/>}
            content={
                <p className={"white-space-pre-line"}>
                    <FormattedMessage id={"navbar.navigationitem.closingdays.onboardingtip.body"}/>
                </p>
            }
            previousButton={{
                text: intl.formatMessage({ id: "navbar.navigationitem.closingdays.onboardingtip.dontshowagain" }),
                iconName: "rioglyph-eye-close",
                onClick: handleDontShowAgain,
            }}
            onHide={() => {
                setShowOnboardingTip(false)
            }}
        >
            {adminNavLink}
        </OnboardingTip>
    )
}

interface ServiceInfoMenuItemComponentProps extends WrappedComponentProps {
    handleOnClickFaq: () => void
    handleOnClickContact: (openOnboarding: boolean) => void
}

const ServiceInfoMenuItemComponent: React.FunctionComponent<ServiceInfoMenuItemComponentProps> = ({
    handleOnClickFaq,
    handleOnClickContact
}: ServiceInfoMenuItemComponentProps) => {
    const intl = useIntl()

    return (
        <ActionBarItem id="serviceInfo">
            <ActionBarItem.Icon>
                <span className="icon rioglyph rioglyph-info-sign"/>
            </ActionBarItem.Icon>
            <ActionBarItem.Popover title={<FormattedMessage id={"application.name"}/>} className={"divider-y"}>
                <ActionBarItem.List>
                    <ActionBarItem.ListItem icon='rioglyph-question-sign' onClick={handleOnClickFaq}>
                        <FormattedMessage id={"application.actionBar.serviceInfo.faq.text"}/>
                    </ActionBarItem.ListItem>
                    <ActionBarItem.ListItem icon='rioglyph-envelope' onClick={() => handleOnClickContact(false)}>
                        <FormattedMessage id={"application.actionBar.serviceInfo.contact.text"}/>
                    </ActionBarItem.ListItem>
                    <ActionBarItem.ListSeparator/>
                    <ActionBarItem.ListItem>

                        <div>
                            <div className="line-height-largest">
                                <a href={intl.formatMessage({ id: "termsAndConditions.agreement.dialog.checkTermsAndConditionsLabel.linkTermsConditions.href" })}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-track-ga-event-trigger="click"
                                    data-track-ga-event-category="dispatch, transportOrder, serviceInfo"
                                    data-track-ga-event-action="termsAndConditions">
                                    <FormattedMessage
                                        id={"application.actionBar.serviceInfo.termsAndConditions.text"}/>
                                </a>
                            </div>
                            <div className="line-height-largest">
                                <FormattedMessage id={"application.actionBar.serviceInfo.version.text"}
                                    values={{ version: config.release.revision?.substring(0, 10) }}/>
                            </div>
                            <div className="line-height-largest">
                                <FormattedMessage id={"application.actionBar.serviceInfo.buildTime.text"}
                                    values={{ buildTime: config.release.builtAt }}/>
                            </div>
                        </div>
                    </ActionBarItem.ListItem>
                </ActionBarItem.List>

            </ActionBarItem.Popover>
        </ActionBarItem>)
}

const ServiceInfoMenuItem = injectIntl(ServiceInfoMenuItemComponent)
const ClosingDaysNavLink = injectIntl(ClosingDaysNavLinkComponent)

export const Application = () => {
    const languageSettings = useAppSelector(getLanguageSettings)
    const displayClosingDaysNavItem = useFeatureToggle("showClosingDays", false)

    const closingDaysNavItem = {
        key: "closingDays",
        route: <ClosingDaysNavLink/>,
    }
    const navItems = [
        {
            key: "welcomePage",
            route: <NavLink
                data-track-ga-event-trigger="click"
                data-track-ga-event-category="dispatch, navigationMenu, welcomePage"
                data-track-ga-event-action="Link"
                to={"/welcome-page"}
            >
                <FormattedMessage id={"navbar.navigationitem.welcome"}/>
            </NavLink>,
        },
        {
            key: "transportorder",
            route: <NavLink
                data-track-ga-event-trigger="click"
                data-track-ga-event-category="dispatch, navigationMenu, transportOrders"
                data-track-ga-event-action="Link"
                to={`${routingConfig.landingPage.path}`}
            >
                <FormattedMessage id={"navbar.navigationitem.dashboard"}/>
            </NavLink>,
        },
        {
            key: "notifications",
            route: <NavLink
                data-track-ga-event-trigger="click"
                data-track-ga-event-category="dispatch, navigationMenu, notifications"
                data-track-ga-event-action="Link"
                to={`${routingConfig.notificationSettings.path}`}
            >
                <FormattedMessage id={"navbar.navigationitem.notifications"}/>
            </NavLink>,
        },
        ...(displayClosingDaysNavItem ? [closingDaysNavItem] : [])
    ]

    const defaultUserMenu = (
        <DefaultUserMenu environment={config.mode} key="userMenu"/>
    )

    const [showFaqDialog, setShowFaqDialog] = useState(false)
    const [showContactDialog, setShowContactDialog] = useState(false)
    const [showOnboardingTab, setShowOnboardingTab] = useState(false)

    const showFaqHandler = () => {
        setShowFaqDialog(true)
    }

    const showContactHandler = (openOnboarding: boolean) => {
        setShowContactDialog(true)
        setShowOnboardingTab(openOnboarding)
    }

    return (
        <CookiesProvider>
            <IntlProvider locale={languageSettings.locale} messages={languageSettings.messages}>
                <div>
                    <Dialog
                        show={showFaqDialog}
                        title={<FormattedMessage id={"application.actionBar.serviceInfo.faq.text"}/>}
                        body={<Faq/>}
                        bsSize={Dialog.SIZE_XL}
                        footer={<Button onClick={() => setShowFaqDialog(false)}
                            variant={Button.VARIANT_OUTLINE}><FormattedMessage
                                id={"application.close"}/></Button>}
                        onClose={() => setShowFaqDialog(false)}
                        className='faqDialog'
                        showCloseButton
                    />
                    <Dialog
                        show={showContactDialog}
                        title={<FormattedMessage id={"application.actionBar.serviceInfo.contact.text"}/>}
                        body={<ContactForm showOnboarding={showOnboardingTab}/>}
                        bsSize={Dialog.SIZE_XL}
                        footer={<Button onClick={() => setShowContactDialog(false)}
                            variant={Button.VARIANT_OUTLINE}><FormattedMessage
                                id={"application.close"}/></Button>}
                        onClose={() => setShowContactDialog(false)}
                        className='contactDialog'
                        showCloseButton
                    />
                    <Agreement/>
                    <ApplicationLayout className={"printable-content"}>
                        <ApplicationLayout.Header>
                            <ApplicationHeader
                                appNavigator={menu}
                                label={<FormattedMessage id={"application.name"}/>}
                                navItems={navItems}
                                actionBarItems={[<ServiceInfoMenuItem handleOnClickFaq={showFaqHandler}
                                    handleOnClickContact={showContactHandler}
                                    key="serviceInfo"/>, defaultUserMenu]}
                            />

                        </ApplicationLayout.Header>
                        <Routes>
                            <Route path="/" index element={<Navigate to="/welcome-page"/>}/>
                            <Route path="/welcome-page" element={<WelcomePage handleOnClickFaq={showFaqHandler}
                                handleOnClickContact={showContactHandler}/>}/>
                            <Route path={routingConfig.transportOrderPresenter.path}
                                element={<TransportOrderFrame/>}/>
                            <Route path={routingConfig.transportOrderOverview.path} element={<OrderOverview/>}/>
                            {displayClosingDaysNavItem && <Route path={routingConfig.closingDays.path}
                                element={<ClosingDaysFrame/>}/>}
                            <Route path={routingConfig.notificationSettings.path} element={<NotificationSettings/>}/>
                            <Route path={"*"}
                                element={<Navigate to={routingConfig.transportOrderOverview.path} replace/>}/>
                        </Routes>
                    </ApplicationLayout>
                    <NotificationsContainer/>
                </div>
            </IntlProvider>
        </CookiesProvider>
    )
}
